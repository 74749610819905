define("discourse/plugins/waze-external-controlled-groups/discourse/initializers/waze-external-controlled-groups", ["exports", "discourse/lib/plugin-api"], function (_exports, e) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var i = {
      d: (e, t) => {
        for (var o in t) i.o(t, o) && !i.o(e, o) && Object.defineProperty(e, o, {
          enumerable: !0,
          get: t[o]
        });
      },
      o: (e, i) => Object.prototype.hasOwnProperty.call(e, i)
    },
    t = {};
  i.d(t, {
    A: () => r
  });
  const o = (l = {
      withPluginApi: () => e.withPluginApi
    }, s = {}, i.d(s, l), s),
    r = {
      name: "external-controlled-groups",
      initialize() {
        (0, o.withPluginApi)("0.8.30", e => {
          e.modifyClass("model:group", {
            pluginId: "waze_external_controlled_groups",
            custom_fields: {},
            asJSON() {
              return Object.assign(this._super(), {
                custom_fields: this.custom_fields
              });
            }
          });
        });
      }
    };
  var l,
    s,
    a = _exports.default = t.A;
});