define("discourse/plugins/waze-external-controlled-groups/discourse/connectors/group-edit/external-controlled-groups", ["exports", "I18n"], function (_exports, e) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var t = {
      d: (e, s) => {
        for (var o in s) t.o(s, o) && !t.o(e, o) && Object.defineProperty(e, o, {
          enumerable: !0,
          get: s[o]
        });
      },
      o: (e, t) => Object.prototype.hasOwnProperty.call(e, t)
    },
    s = {};
  t.d(s, {
    A: () => a
  });
  const o = (r = {
      default: () => e.default
    }, l = {}, t.d(l, r), l),
    a = {
      setupComponent(e, t) {
        var s, a, r;
        const l = null !== (a = null === (s = o.default.translations.en.js.waze) || void 0 === s ? void 0 : s.external_controlled_groups) && void 0 !== a ? a : {},
          i = l.provider || {},
          n = Object.keys(i).map(e => ({
            id: e,
            name: o.default.t(`js.waze.external_controlled_groups.provider.${e}`) || i[e]
          }));
        this.set("externalProviders", n);
        const c = t.siteSettings,
          u = c.waze_external_controlled_user_flag.split("|");
        this.set("userFlagOptions", u);
        const g = c.waze_external_controlled_welcome_email_template_name.split("|").map(e => ({
          id: e,
          name: e
        }));
        this.set("welcomeEmailTemplateNameOptions", g);
        const p = null === (r = this.group.custom_fields) || void 0 === r ? void 0 : r.user_flags;
        p ? this.set("userFlagsArray", p.split("|")) : this.set("userFlagsArray", []);
        const d = l.category || {},
          m = Object.keys(d).map(e => ({
            id: e,
            name: o.default.t(`js.waze.external_controlled_groups.category.${e}`) || d[e]
          }));
        this.set("groupCategories", m);
      },
      actions: {
        changeUserFlags(e) {
          this.set("userFlagsArray", e), this.group.set("custom_fields", Object.assign(Object.assign({}, this.group.custom_fields), {
            user_flags: e.join("|")
          }));
        },
        resetWelcomeEmail() {
          this.group.set("custom_fields", Object.assign(Object.assign({}, this.group.custom_fields), {
            welcome_email_template_name: ""
          }));
        },
        resetGroupCategory() {
          this.group.set("custom_fields", Object.assign(Object.assign({}, this.group.custom_fields), {
            group_category: ""
          }));
        }
      }
    };
  var r,
    l,
    i = _exports.default = s.A;
});